import withUser from '@/withUser';
import Head from 'next/head';
import Header from '@components/Header';
import React, { useMemo } from 'react';
import FrontLayout from '@layouts/FrontLayout';
import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import UIWidth from '@components/presentational/UIWidth';
import Chapo from '@components/presentational/Chapo';
import RessourcesPicto from '@/components/presentational/img/icons/RessourcesPicto';
import SimpleCard from '@components/presentational/Cards/SimpleCard';
import CardHeader from '@components/presentational/Cards/Parts/CardHeader';
import IconForButton from '@components/presentational/IconForButton';
import {
  PiCaretLeftThin,
  PiCaretRightThin,
  PiChatsThin,
  PiCheckThin,
  PiFoldersThin,
  PiGlobeSimpleThin,
  PiListMagnifyingGlassThin,
  PiMapPinLineThin,
  PiMegaphoneThin,
  PiMonitorThin,
  PiShareNetworkThin,
} from 'react-icons/pi';
import SliderIcon from '@components/presentational/SliderIcon';
import { searchPublications } from '@/api/publication';
import { Publication as PublicationConstants } from '@/types/constants';
import { SearchResult } from '@/types/search/searchresult';
import { Resource } from '@/types/core/Resource';
import FormationPicto from '@/components/presentational/img/icons/FormationPicto';
import ComplexCard from '@components/presentational/Cards/ComplexCard';
import CardBody from '@components/presentational/Cards/Parts/CardBody';
import CardFooter from '@components/presentational/Cards/Parts/CardFooter';
import Mapemonde from '@/components/presentational/img/v2024/Mapemonde.svg';

import { searchCourses } from '@/api/course';
import { Course } from '@/types/core/Course';
import CourseListItem from '@components/course/CourseListItem';
import CommunityPicto from '@/components/presentational/img/icons/CommunityPicto';
import NextLink from 'next/link';
import { News } from '@/types/core/News';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { ReduxRootState } from '@/types/store/ReduxRootState';
import RegisterHomeForm from '@components/app/RegisterHomeForm';
import { useRouter } from 'next/router';
import BackgroundRessource1 from '@/public/home/section_ressources_1.svg';
import BackgroundRessource2 from '@/public/home/section_ressources_2.svg';
import BackgroundCourses1 from '@/public/home/section_courses_1.svg';
import BackgroundCourses2 from '@/public/home/section_courses_2.svg';
import BackgroundCommunaute1 from '@/public/home/section_communaute_1.svg';
import BackgroundCommunaute2 from '@/public/home/section_communaute_2.svg';
import BackgroundRegister1 from '@/public/home/section_register_1.svg';
import { getUserCoursesSessions } from '@/api/courseSession';
import { CourseSession } from '@/types/core/CourseSession';
import { NextPageContextWithRedux } from '@/types/store/NextPageContextWithRedux';
import HeroBannerHome from '@components/app/HeroBannerHome';
import { ResourceCard } from '@components/ressource/ResourceCard';
import Carousel from '@components/app/Carousel';

interface Props {
  featuredResources: SearchResult<Resource>;
  courses: SearchResult<Course>;
  news: SearchResult<News>;
  coursesSessions: CourseSession[];
}

const CommunityCardFooterButton = ({ label, ...props }) => {
  return (
    <Button
      as={'a'}
      variant="sunflower"
      lineHeight="1.2em"
      width={{ base: '100%', sm: 'revert' }}
      {...props}
    >
      {label}
    </Button>
  );
};

const NewsListItem = ({ news }: { news: News }) => {
  const date = useMemo(() => {
    return dayjs(news.updatedAt).format('DD/MM/YY');
  }, [news.updatedAt]);

  return (
    <ComplexCard
      image={
        news.image?.filename
          ? `/data/event/images/${news.image?.filename}`
          : null
      }
      imageAlt={news.title}
      accentColor="tournesol.100"
      width={['calc(100% - 4rem)', '', '100%']}
      mx="auto"
    >
      <Text>
        Publié par {news.author?.displayName || 'Anonyme'} le {date}
      </Text>
      <CardHeader title={news.title} />
      <CardBody>
        <Text>{news.abstract}</Text>
      </CardBody>
      <CardFooter>
        <Flex w="100%" justify={{ base: 'center', sm: 'flex-end' }}>
          <NextLink
            href={{
              pathname: '/info/detail',
              query: { publication: news.id },
            }}
            as={`/info/${news.slug}/${news.id}`}
            passHref
          >
            <Button as={'a'} variant="sunflower" lineHeight="1.2em">
              Consulter la publication
            </Button>
          </NextLink>
        </Flex>
      </CardFooter>
    </ComplexCard>
  );
};

function PageHome({
  featuredResources,
  courses,
  news,
  coursesSessions,
}: Props) {
  const user = useSelector(
    (state: ReduxRootState) => state.authentication.user
  );

  const { push } = useRouter();

  const ressourcesPresentationCards = [
    {
      id: 1,
      title: `Des ressources sélectionnées pour leur qualité`,
      content: `L'équipe IFprofs a minutieusement choisi et labellisé toutes les ressources de cette rubrique. Nous avons privilégié la qualité des contenus et la diversité des formats. Chaque ressource est unique et a fait l'objet d'une attention particulière pour assurer sa pertinence.`,
      icon: PiCheckThin,
    },
    {
      id: 2,
      title: `Un moteur de recherche précis`,
      content: `Nos ressources sont classées par niveaux de langue, thématiques, publics et compétences. Cela vous permet de faire des recherches rapides et efficaces pour répondre à vos besoins du quotidien mais aussi pour vous inspirer.`,
      icon: PiListMagnifyingGlassThin,
    },
    {
      id: 3,
      title: `Des ressources à partager`,
      content: `Vos contributions sont essentielles. Nous vous invitons à partager vos idées de ressources pédagogiques avec toute la communauté FLE d'IFprofs. Une fois validées par notre équipe, celles-ci seront publiées et valorisées dans l'espace dédié.`,
      icon: PiShareNetworkThin,
    },
  ];

  return (
    <FrontLayout
      gtmPage={{
        page_type: 'Commun',
        page_level1: 'Accueil',
      }}
    >
      <Head>
        <>
          <title>
            IFprofs - Réseau mondial de professeurs et ressources pour
            l'enseignement du français
          </title>
          <meta
            key={'description'}
            name={'description'}
            content={
              'Intégrez la communauté IFprofs, échangez avec des collègues enseignants et formateurs du monde entier et enrichissez votre enseignement du français. Inscrivez-vous gratuitement et découvrez nos ressources pédagogiques.'
            }
          />
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebSite",
                "url": "https://ifprofs.org/",
                "potentialAction": {
                  "@type": "SearchAction",
                  "target": "https://ifprofs.org/recherche?s={search_term_string}",
                  "query-input": "required name=search_term_string"
                }
              }
            `}
          </script>
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "Organization",
                "url": "https://ifprofs.org/",
                "logo": "https://ifprofs.org/assets/LogoInstitutFrancais.svg",
                "contactPoint": [
                  {
                    "@type": "ContactPoint",
                    "telephone": "+33-01-53-69-83-00",
                    "email": "ifprofs@ifprofs.org"
                  }
                ]
              }
            `}
          </script>
        </>
      </Head>
      <Header />
      <HeroBannerHome />
      <section data-name="ressources">
        <Box
          backgroundImage={`url("${BackgroundRessource1.src}")`}
          backgroundRepeat="no-repeat"
          backgroundPosition={{
            base: '50% 100rem',
            sm: '50% 30rem',
            md: '50% 44rem',
          }}
          backgroundSize="100vw auto"
          pt={{ base: '2rem', md: '4rem' }}
          pb={{ base: '8rem', md: '2rem' }}
        >
          <Box
            backgroundImage={`url("${BackgroundRessource2.src}")`}
            backgroundRepeat="no-repeat"
            backgroundPosition={{
              base: '50% 17rem',
              sm: '50% 10rem',
              md: '50% 11rem',
            }}
            backgroundSize="100% auto"
          >
            <UIWidth>
              <Chapo
                heading="Ressources pédagogiques"
                titleLevel="h2"
                icon={
                  <RessourcesPicto
                    freeIconColor="currentColor"
                    iconWidth="100%"
                  />
                }
                color="tuile.100"
              >
                <Text my="1rem" fontSize={{ md: '1.25rem' }}>
                  Retrouvez des centaines de ressources de qualité, produites
                  par l'Institut français, ses partenaires et les membres
                  d'IFprofs.
                </Text>
                <Text
                  mt="1rem"
                  mb={{ base: '3rem', md: '1rem' }}
                  fontSize={{ md: '1.25rem' }}
                >
                  Notre objectif : vous faire gagner du temps lors de la
                  préparation de vos cours et offrir aux apprenants des
                  activités riches et variées.
                </Text>
              </Chapo>
              <SimpleGrid
                templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }}
                gap={{ base: '1rem', md: '1.25rem' }}
              >
                {ressourcesPresentationCards.map((item, index) => (
                  <SimpleCard
                    accentColor="tuile.100"
                    width="100%"
                    key={index}
                    height={{ md: 'calc(100% - .625rem)' }}
                  >
                    <CardHeader
                      title={item.title}
                      icon={item.icon}
                      iconLeftForXs
                      homeFontSize
                    />
                    {item.content}
                  </SimpleCard>
                ))}
              </SimpleGrid>
            </UIWidth>
            <Box
              backgroundImage={{
                base: `url("${BackgroundRessource1.src}")`,
                sm: 'none',
              }}
              backgroundRepeat="no-repeat"
              backgroundPosition="50% 1rem"
              backgroundSize="100vw auto"
              pt={{ base: '2rem', md: '4rem' }}
              pb={{ md: '10rem' }}
            >
              <UIWidth>
                <Box textAlign="center">
                  <NextLink passHref href="/ressources">
                    <Button
                      as={'a'}
                      width={{ base: '100%', sm: 'revert' }}
                      px={{ base: '.5rem', sm: '1em' }}
                    >
                      <IconForButton as={PiFoldersThin} mr=".75em" />
                      <Text as="span">Explorer les ressources</Text>
                    </Button>
                  </NextLink>
                </Box>
              </UIWidth>
              <Box
                pt={{ base: '2rem', md: '2rem' }}
                pb={{ base: '6rem', md: '2rem' }}
              >
                <UIWidth>
                  <Heading
                    color="brand.100"
                    fontWeight="600"
                    letterSpacing={{ base: '-1px', sm: null }}
                    mb="1rem"
                    mt="2rem"
                  >
                    Ressources à la Une
                  </Heading>
                  <Carousel variant={'tuile'}>
                    {featuredResources.items.map(publication => (
                      <ResourceCard
                        key={publication.id}
                        publication={publication}
                      />
                    ))}
                  </Carousel>
                  <Text textAlign="center" pt={{ md: '4rem' }}>
                    <NextLink passHref href={'/ressources'}>
                      <Button as={'a'} width={{ base: '100%', sm: 'revert' }}>
                        <IconForButton as={PiFoldersThin} mr=".75em" />
                        <Text as="span" lineHeight={{ base: '1', sm: null }}>
                          Voir toutes les ressources
                        </Text>
                      </Button>
                    </NextLink>
                  </Text>
                </UIWidth>
              </Box>
            </Box>
          </Box>
        </Box>
      </section>

      <section data-name="formations">
        <Box
          backgroundImage={`url("${BackgroundCourses1.src}")`}
          backgroundRepeat="no-repeat"
          backgroundPosition={{ base: '0 0', md: '0 0' }}
          backgroundSize="100vw auto"
          mt={{ base: '-13rem', md: '-10rem' }}
          pt={{ base: '3rem', sm: '5rem', md: '6rem', lg: '9rem' }}
          pb={{ base: '4rem', md: '10rem' }}
        >
          <Box
            backgroundImage={`url("${BackgroundCourses2.src}")`}
            backgroundSize="100% auto"
            backgroundRepeat="no-repeat"
            backgroundPosition={{
              base: '50% 19rem',
              sm: '50% 11rem',
              md: '50% 14rem',
            }}
            pb="2rem"
            pt="1rem"
          >
            <UIWidth>
              <Chapo
                heading="Formations"
                titleLevel="h2"
                icon={
                  <FormationPicto
                    freeIconColor="currentColor"
                    iconWidth="100%"
                  />
                }
                color="menthe.100"
              >
                <Text my={4} fontSize={{ md: '1.25rem' }}>
                  Grâce aux formations FLE spécialement conçues pour vous avec
                  des experts de chaque domaine, IFprofs vous propose de
                  développer vos connaissances et votre pratique
                  professionnelle.
                </Text>
                <Text my={4} fontSize={{ md: '1.25rem' }}>
                  Vous pouvez suivre de courts modules en autonomie, à votre
                  rythme, et obtenir une attestation en fin de parcours.
                </Text>
              </Chapo>
              <SimpleGrid
                templateColumns={{
                  base: 'repeat(1fr)',
                  sm: 'repeat(2, 1fr)',
                }}
                gap={{ base: '1rem', md: '1.25rem' }}
                mt="6rem"
                mb="2rem"
                pb={{ base: '1rem', md: '0' }}
              >
                {courses?.items &&
                  courses.items.map(course => (
                    <CourseListItem
                      key={course.id}
                      course={course}
                      coursesSessions={coursesSessions}
                    />
                  ))}
              </SimpleGrid>
              <Text textAlign="center">
                <NextLink
                  as={`/formations`}
                  passHref
                  href={{
                    pathname: '/formations',
                  }}
                >
                  <Button as={'a'} width={{ base: '100%', sm: 'revert' }}>
                    <IconForButton as={PiMonitorThin} mr=".75em" />
                    <Text as="span" lineHeight={{ base: '1', sm: null }}>
                      Découvrir toutes nos formations
                    </Text>
                  </Button>
                </NextLink>
              </Text>
            </UIWidth>
          </Box>
        </Box>
      </section>

      <section data-name="communaute">
        <Box
          backgroundImage={`url("${BackgroundCommunaute1.src}")`}
          backgroundRepeat="no-repeat"
          backgroundPosition="50% 0"
          backgroundSize="100vw auto"
          mt={{ base: '-4rem', md: '-10rem' }}
          pb={{ base: '6rem', md: '14rem' }}
          pt={{ base: '3rem', md: '6rem', lg: '9rem' }}
        >
          <Box
            background={{
              md: `url("${BackgroundCommunaute2.src}") no-repeat 50% 85vh / 100% auto`,
            }}
            backgroundPosition={{ lg: '50% 30rem' }}
          >
            <UIWidth>
              <Chapo
                heading="Communautés"
                baseLetterSpacing="-1.6px"
                titleLevel="h2"
                icon={
                  <CommunityPicto
                    freeIconColor="currentColor"
                    iconWidth="100%"
                  />
                }
                color="tournesol.100"
              >
                <Text my={4} fontSize={{ md: '1.25rem' }}>
                  Nous partageons des actualités sur
                  l'enseignement-apprentissage de la langue française, mais bien
                  plus encore !
                </Text>
                <Text my={4} fontSize={{ md: '1.25rem' }}>
                  Dans cette rubrique, vous pouvez vous connecter à la
                  communauté de votre pays pour partager vos expériences et
                  conseils, rejoindre des groupes d'intérêts ou créer votre
                  propre groupe.
                </Text>
                <Text my={4} fontSize={{ md: '1.25rem' }}>
                  L'espace idéal pour s'informer et créer du lien.
                </Text>
              </Chapo>
              <SimpleGrid
                display="grid"
                gap={{ base: '2rem', md: '1.25rem' }}
                templateColumns={{
                  base: 'repeat(1fr)',
                  md: 'repeat(3, 1fr)',
                }}
                pb={{ md: '1em' }}
                mb={{ base: ' 4rem', md: 'revert' }}
              >
                <SimpleCard
                  accentColor="tournesol.100"
                  height="100%"
                  width="100%"
                >
                  <CardHeader
                    title="Les actualités d'IFprofs"
                    icon={PiMegaphoneThin}
                    iconLeftForXs
                  />
                  <Text>
                    Nous vous informons des événements, formations, offres
                    d'emploi, colloques et autres annonces en lien avec
                    l'enseignement de la langue française et la francophonie.
                  </Text>
                  <CardFooter>
                    <Flex justify="flex-end" w="100%">
                      <NextLink passHref href={'/communautes'}>
                        <CommunityCardFooterButton label="Consulter les actualités" />
                      </NextLink>
                    </Flex>
                  </CardFooter>
                </SimpleCard>
                <SimpleCard
                  accentColor="tournesol.100"
                  height="100%"
                  width="100%"
                >
                  <CardHeader
                    title="Une communauté dédiée à votre pays"
                    icon={PiMapPinLineThin}
                    iconLeftForXs
                  />
                  <Text>
                    Lors de votre inscription, vous devenez membre de la
                    communauté IFprofs de votre pays. Vous y trouvez des
                    collègues ayant des intérêts et des problématiques
                    similaires, les infos locales et des ressources
                    correspondant à votre contexte. C'est un espace de partage
                    et d'échanges auquel vous pouvez contribuer activement.
                  </Text>
                  <CardFooter>
                    <Flex justify="flex-end" w="100%">
                      <NextLink
                        passHref
                        href={{
                          pathname: '/communautes',
                          query: {
                            activeTab: 1,
                          },
                        }}
                        as={`/communautes?activeTab=1`}
                      >
                        <CommunityCardFooterButton label="Voir la communauté de mon pays" />
                      </NextLink>
                    </Flex>
                  </CardFooter>
                </SimpleCard>
                <SimpleCard
                  accentColor="tournesol.100"
                  height="100%"
                  width="100%"
                >
                  <CardHeader
                    title="Les groupes publics et privés"
                    icon={PiChatsThin}
                    iconLeftForXs
                  />
                  <Text>
                    Qu'ils soient thématiques ou liés à un projet particulier,
                    les groupes vous permettent d'échanger facilement sur un
                    sujet précis et de partager des documents avec ses membres.
                  </Text>
                  <CardFooter>
                    <Flex justify="flex-end" w="100%">
                      <CommunityCardFooterButton
                        label="Découvrir les groupes"
                        href="/communautes/groupes"
                      />
                    </Flex>
                  </CardFooter>
                </SimpleCard>
              </SimpleGrid>
              <Heading
                color="brand.100"
                mt={{ base: '1rem', md: '3rem' }}
                mb="1em"
                fontWeight="600"
              >
                Actualités du moment
              </Heading>
              <Carousel
                variant={'tournesol'}
                templateColumns={{ md: 'repeat(3, 1fr)' }}
                blackIcon
              >
                {news.items &&
                  news.items.map(news => (
                    <NewsListItem key={news.id} news={news} />
                  ))}
              </Carousel>
              <Text mt="2rem" textAlign="center">
                <NextLink href={'/communautes'} passHref>
                  <Button as={'a'} width={{ base: '100%', sm: 'revert' }}>
                    <IconForButton as={PiGlobeSimpleThin} mr=".75em" />
                    <Text as="span" lineHeight={{ base: '1', sm: null }}>
                      Consulter toutes les publications
                    </Text>
                  </Button>
                </NextLink>
              </Text>
            </UIWidth>
          </Box>
        </Box>
      </section>

      {!user && (
        <section data-name="register or login">
          <Box
            backgroundImage={{ md: `url("${BackgroundRegister1.src}")` }}
            backgroundRepeat="no-repeat"
            backgroundPosition={{ md: '50% 0' }}
            backgroundSize="100vw auto"
            backgroundColor={{ base: 'cumulus.100', md: 'transparent' }}
            mt={{ base: '-4rem', md: '-10rem' }}
            pt={{ base: '4rem', md: '10vw' }}
            pb={{ base: '4rem', md: '5rem' }}
          >
            <UIWidth>
              <Heading
                fontWeight="600"
                color="black"
                fontSize={{ base: '2rem', md: '3.1875rem' }}
                mb={{ md: '2.5rem' }}
              >
                Vivez l’expérience IFprofs
              </Heading>
              <Grid
                templateColumns={{ base: 'repeat(1, 1fr)', md: 'auto 25rem' }}
                gap={0}
              >
                <Box>
                  <Text
                    mt={{ base: '1rem', md: '3rem' }}
                    mb={{ base: '1rem', md: '3.75rem' }}
                    fontSize={{ base: '1.25rem', md: '1.5rem' }}
                    mr={{ md: '4rem' }}
                    lineHeight="1.2em"
                  >
                    Devenez membre et accédez à des ressources de qualité, des
                    formations et une communauté dynamique de professionnels de
                    l'enseignement du et en français.
                  </Text>
                  <Box
                    display={{ base: 'none', md: 'block' }}
                    aspectRatio={{ md: '769 / 393' }}
                    background={{
                      md: `url(${Mapemonde.src}) no-repeat center / 100% auto`,
                    }}
                    mr={{ md: '3.75rem' }}
                  />
                </Box>
                <Box>
                  <RegisterHomeForm
                    onSubmitEnd={(idUnfinishedSignup, country) => {
                      const query = {
                        country: country.code,
                        id: idUnfinishedSignup,
                      };
                      // TODO : Set to correct route after creating page
                      push(
                        {
                          pathname: '/s-inscrire/a-propos-de-vous',
                          query,
                        },
                        `/s-inscrire/a-propos-de-vous/${query.id}`
                      ).then(() =>
                        window.scrollTo({
                          top: 0,
                          behavior: 'instant',
                        })
                      );
                    }}
                  />
                </Box>
              </Grid>
            </UIWidth>
          </Box>
        </section>
      )}
    </FrontLayout>
  );
}

PageHome.getInitialProps = async (context: NextPageContextWithRedux) => {
  const currentUser = context.reduxStore.getState().authentication.user;

  const getUserSessions = async () => {
    if (currentUser) {
      return getUserCoursesSessions({ context });
    } else {
      return Promise.resolve([]);
    }
  };

  const [featuredResources, courses, news, coursesSessions] = await Promise.all(
    [
      searchPublications(
        {
          fields: {
            publication:
              'title,createdAt,updatedAt,slug,text,countSelections,countRecommendations',
            resource: 'subtitle,requiredCECRLevels,categories,criteria',
            user: 'displayName',
            file: 'filename',
          },
          relationIncludes: ['author', 'author.avatar', 'resource', 'image'],
          filters: {
            labelized: 'true',
            featured: 'true',
            type: 'resource',
            publishedStatus: PublicationConstants.STATE_PUBLISHED,
          },
          orders: ['-createdAt'],
          pagination: { itemsPerPage: 4 },
        },
        { context }
      ),
      searchCourses(
        {
          fields: {
            course:
              'id,title,duration,designer,summary,skills,status,targets,successRate,description,keywords,media,updatedAt,countSubscribedUsers,countCertifiedUsers',
            chapter: 'id,title,rank,type,parent',
            file: 'filename,originalFilename,size,uploadId',
          },
          relationIncludes: ['media', 'chapters'],
          filters: { status: 'PUBLISHED' },
          orders: ['createdAt', 'random'],
          pagination: { itemsPerPage: 2 },
        },
        { context }
      ),
      searchPublications(
        {
          fields: {
            publication:
              'title,slug,publishedAt,updatedAt,text,abstract,imageAlt',
            country: 'name',
            user: 'displayName',
            file: 'filename,originalFilename',
          },
          relationIncludes: ['country', 'author', 'image'],
          filters: {
            types: 'news',
            publishedStatus: 'state.published',
            worldOrCountry: 'null',
            featured: 'true',
          },
          orders: ['-publishedAt'],
          pagination: { itemsPerPage: 3 },
        },
        { context }
      ),
      getUserSessions(),
    ]
  );

  return {
    featuredResources,
    courses,
    news,
    coursesSessions,
  };
};
export default withUser(PageHome);
