import React from 'react';
import Svg from './Svg';

export default function RessourcesPicto(props) {
  return (
    <Svg
      {...props}
      d="M485.526,238.992l-0,645.38l-160.756,-0l-0,-754.223l160.756,0l-0,68.256l127.254,-64.114l339.356,673.562l-143.565,72.33l-323.045,-641.191Zm-287.816,-108.843l0,754.223l-160.756,-0l0,-754.223l160.756,0Z"
    />
  );
}
