import { Resource } from '@/types/core/Resource';
import React, { useMemo } from 'react';
import resource from '@/translations/resource.fr.yml';
import pedagogicalResource from '@/translations/pedagogicalResource.fr.yml';
import dayjs from 'dayjs';
import HomeFeaturedCard from '@components/presentational/Cards/HomeFeaturedCard';

export function ResourceCard({
  publication,
  accentColor = 'tuile.100',
  extraQueryParamsForDetailLink,
}: {
  publication: Resource;
  accentColor?: string;
  extraQueryParamsForDetailLink?: Record<string, string>;
}) {
  const criteriasLabel = useMemo<string[] | null>(() => {
    if (publication.criteria.length === 0) return null;
    return publication.criteria
      .filter(skill => skill.startsWith('criteria.skills'))
      .map(skill => {
        const skillParts = skill.split('.');
        return resource['criteria'][`${skillParts[1]}`][`${skillParts[2]}`];
      });
  }, [publication.criteria]);

  const themesLabel = useMemo<string[]>(() => {
    return publication.categories
      .map(theme => {
        const categoryParts = theme.split('.');
        if (categoryParts.length === 2) {
          return resource[theme];
        }
      })
      .filter(theme => theme);
  }, [publication.categories]);

  // This code is necessary because some categories do not exist in the resource translation file.
  const categoriesLabel = useMemo<string[] | null>(() => {
    const result = publication.categories.reduce((acc, category) => {
      const categoryParts = category.split('.');
      const categoryResource = resource['category'][`${categoryParts[1]}`];
      if (categoryResource && categoryResource[`${categoryParts[2]}`]) {
        acc.push(categoryResource[`${categoryParts[2]}`]);
      }
      return acc;
    }, []);
    if (result.length === 0) {
      return null;
    } else {
      return result;
    }
  }, [publication.categories]);

  const CECRLevels = useMemo<string[] | null>(() => {
    return publication.requiredCECRLevels?.map(
      level => pedagogicalResource['cecr_level'][level]
    );
  }, [publication.requiredCECRLevels]);

  const date = useMemo(() => {
    return dayjs(publication.updatedAt).format('DD/MM/YY');
  }, [publication.updatedAt]);

  return (
    <HomeFeaturedCard
      mt="1rem"
      index={publication.id}
      key={publication.id}
      color={accentColor}
      title={publication.title}
      avatar={
        publication.author?.avatar?.filename
          ? `/data/avatars/${publication.author?.avatar?.filename}`
          : null
      }
      author={publication.author?.displayName}
      date={date}
      resume={publication.subtitle}
      themes={themesLabel.length > 0 ? themesLabel : null}
      levels={CECRLevels}
      categories={categoriesLabel}
      skills={criteriasLabel}
      likes={publication.countSelections}
      shares={publication.countRecommendations}
      href={{
        pathname: '/ressource/detail',
        query: {
          publication: publication.id,
          ...extraQueryParamsForDetailLink,
        },
      }}
      url={`/ressource/${publication.slug}/${publication.id}`}
    />
  );
}
