import { Box, Flex, Heading, Text } from '@chakra-ui/react';

export default function Chapo({
  heading,
  icon = null,
  iconWidth = '11.875rem',
  color = 'brand.100',
  children = null,
  baseLetterSpacing = null,
  titleLevel = 'h1',
}) {
  return (
    <Flex
      as="header"
      gap="1.25rem"
      display={!icon ? 'block' : null}
      me={{ lg: '13rem' }}
      mb={{ md: 8 }}
      alignItems="center"
    >
      {icon && (
        <Box
          as="span"
          color={color}
          width={iconWidth}
          height={iconWidth}
          aria-hidden="true"
          display={{ base: 'none', md: 'block' }}
          flexShrink={0}
        >
          {icon}
        </Box>
      )}
      <Box>
        <Heading as={titleLevel} variant="h1" mb={8}>
          <Flex
            as="span"
            alignItems="center"
            gap=".625rem"
            display={!icon ? 'block' : null}
          >
            {icon && (
              <Box
                as="span"
                color={color}
                width="3.75rem"
                height="3.75rem"
                aria-hidden="true"
                display={{ md: 'none' }}
                flexShrink={0}
              >
                {icon}
              </Box>
            )}
            <Text
              as="span"
              letterSpacing={{
                base: baseLetterSpacing,
                sm: 'normal',
              }}
            >
              {heading}
            </Text>
          </Flex>
        </Heading>
        {children}
      </Box>
    </Flex>
  );
}
