import { getIn, setIn } from 'formik';

export default function mapApiErrorsToFormikErrors(apiErrors) {
  let formikErrors = {};
  for (let violation of apiErrors.errors.violations) {
    if (typeof getIn(formikErrors, violation.propertyPath) === 'undefined') {
      formikErrors = setIn(formikErrors, violation.propertyPath, []);
    }

    formikErrors = setIn(
      formikErrors,
      violation.propertyPath,
      getIn(formikErrors, violation.propertyPath).concat([violation.title])
    );
  }

  return formikErrors;
}
