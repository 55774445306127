import useValidationForRequiredField from '@/form/useValidationForRequiredField';
import { Field, getIn } from 'formik';
import React from 'react';
import {
  ComboboxProps,
  Size,
} from '@components/app/form/select/downshift/Combobox';
import FormElement from '@components/presentational/Form/FormElement';
import { Box, useTheme } from '@chakra-ui/react';

type FormikDownshiftSelectProps<GenericProps> = Omit<
  ComboboxProps,
  'loadSelectItems'
> & {
  name: string;
  required?: boolean;
  validate?: any;
  requiredMessage?: string;
  SelectComponent: React.ComponentType<
    Omit<ComboboxProps, 'loadSelectItems'> & {
      filters?: Record<string, string>;
      fields?: Record<string, string>;
      orders?: string[];
    }
  >;
  filters?: Record<string, string>;
  fields?: Record<string, string>;
  orders?: string[];
  id?: string;
  allowClear?: boolean;
  label?: string;
  disabled?: boolean;
  size?: Size;
  fontSize?: string;
  isRequiredIndicatorNotDisplayed?: boolean;
} & GenericProps;

export default function FormikDownshiftSelect<GenericProps>({
  name,
  required = false,
  validate,
  requiredMessage,
  SelectComponent,
  id,
  filters,
  fields,
  allowClear = true,
  label,
  disabled = false,
  size,
  fontSize,
  orders,
  isRequiredIndicatorNotDisplayed,
  ...props
}: FormikDownshiftSelectProps<GenericProps>) {
  const validateForm = useValidationForRequiredField(
    required,
    validate,
    requiredMessage
  );
  const theme = useTheme();

  return (
    <Field name={name} validate={validateForm}>
      {({ field, form }) => {
        const errors = getIn(form.errors, name);
        const touched = getIn(form.touched, name);
        const hasError = errors && touched;

        let errorsComponents = [];
        if (errors) {
          errorsComponents = errors.map(errorItem => (
            <li key={errorItem}>{errorItem}</li>
          ));
        }

        return (
          <>
            <Box maxWidth={theme.maxFieldsWidth}>
              <FormElement
                label={label}
                isDisabled={disabled}
                isInvalid={hasError}
                isRequired={required}
                errorMessage={errorsComponents}
                isRequiredIndicatorNotDisplayed={
                  isRequiredIndicatorNotDisplayed
                }
              >
                <SelectComponent
                  selectedItem={field.value}
                  size={size}
                  fontSize={fontSize}
                  id={id}
                  invalid={hasError}
                  disabled={disabled}
                  onChange={value => {
                    field.onBlur(name)(true);
                    field.onChange(name)(value);
                  }}
                  onBlur={() => {
                    field.onBlur(name)(true);
                  }}
                  filters={filters}
                  fields={fields}
                  orders={orders}
                  allowClear={allowClear}
                  {...props}
                />
              </FormElement>
            </Box>
          </>
        );
      }}
    </Field>
  );
}
