import React from 'react';
import Svg from './Svg';

export default function CommunityPicto(props) {
  return (
    <Svg
      {...props}
      d="M550.25 241.066H452.906V690.655H550.25V241.066Z M960.001 386.647L879.395 332.072L627.337 704.359L707.944 758.934L960.001 386.647Z M292.058 758.935L372.664 704.361L120.607 332.074L40.0002 386.648L292.058 758.935Z"
    />
  );
}
