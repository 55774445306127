import { ComboboxProps } from '@components/app/form/select/downshift/Combobox';
import { ComboboxItem } from '@components/app/form/select/downshift/types';
import ApiCombobox from '@components/app/form/select/downshift/ApiCombobox';
import { Country } from '@/types/core/Country';

export type CountryComboboxProps = Omit<ComboboxProps, 'loadSelectItems'> & {
  normalizer?: (item: any) => ComboboxItem;
  filters?: Record<string, string>;
  includes?: string[];
  fields?: Record<string, string>;
};

export function countryComboboxNormalizer(country: Country): ComboboxItem {
  return {
    id: country.id as string,
    label: country.name as string,
    item: country,
  };
}

export default function CountryCombobox({
  placeholder = 'Rechercher un pays',
  includes,
  filters,
  normalizer,
  fields = { country: 'name' },
  ...otherProps
}: CountryComboboxProps) {
  return (
    <>
      <ApiCombobox
        placeholder={placeholder}
        url={'/api/countries'}
        orders={['name']}
        includes={includes}
        filters={filters}
        fields={fields}
        normalizer={normalizer ? normalizer : countryComboboxNormalizer}
        apiClient={fetch}
        {...otherProps}
      />
    </>
  );
}
