import {
  Icon,
} from '@chakra-ui/react';

export default function IconForButton({ as, mr = null, display = null }) {
  return(
    <Icon
      display={display ? display : null}
      boxSize="1.777em"
      as={as}
      mr={mr ? mr : ".5em"}
      mb="-.0625em"
    />
  );
}
