import {
  useDisclosure,
  Button,
  Box,
  Flex,
  Heading,
  Image,
  Text,
  VisuallyHidden,
  Icon,
  VStack,
  List,
  ListItem,
  ListIcon,
  HStack,
  useMediaQuery,
} from '@chakra-ui/react';
import {
  PiPlusThin,
  PiCaretDoubleRightBold,
  PiFoldersThin,
  PiMonitorThin,
  PiGlobeSimpleThin,
} from 'react-icons/pi';
// import IfprofsHpHero1Xs from '@/public/home/IfprofsHpHero1Xs.webp';
// import IfprofsHpHero1Md from '@/public/home/IfprofsHpHero1Md.webp';
import IfprofsHpHero2Xs from '@/public/home/IfprofsHpHero2Xs.webp';
import IfprofsHpHero2Md from '@/public/home/IfprofsHpHero2Md.webp';
import IfprofsHpHero3Xs from '@/public/home/IfprofsHpHero3Xs.webp';
import IfprofsHpHero3Md from '@/public/home/IfprofsHpHero3Md.webp';
import IfprofsHpHero4Xs from '@/public/home/IfprofsHpHero4Xs.webp';
import IfprofsHpHero4Md from '@/public/home/IfprofsHpHero4Md.webp';
import IfprofsHpHero5Xs from '@/public/home/IfprofsHpHero5Xs.webp';
import IfprofsHpHero5Md from '@/public/home/IfprofsHpHero5Md.webp';
import IfprofsHpHero6Xs from '@/public/home/IfprofsHpHero6Xs.webp';
import IfprofsHpHero6Md from '@/public/home/IfprofsHpHero6Md.webp';
import React from 'react';
import HeroBackgroundImage from '@components/presentational/Banners/HeroBackgroundImage';
import IconForButton from '@components/presentational/IconForButton';
import NextLink from 'next/link';
import UIWidth from '@components/presentational/UIWidth';

const heroImages = [
  // { xs: IfprofsHpHero1Xs, md: IfprofsHpHero1Md },
  { xs: IfprofsHpHero2Xs, md: IfprofsHpHero2Md },
  { xs: IfprofsHpHero3Xs, md: IfprofsHpHero3Md },
  { xs: IfprofsHpHero4Xs, md: IfprofsHpHero4Md },
  { xs: IfprofsHpHero5Xs, md: IfprofsHpHero5Md },
  { xs: IfprofsHpHero6Xs, md: IfprofsHpHero6Md },
];

export function HeroBannerListItem({ strongText, regularText }) {
  return (
    <ListItem display="flex" alignItems="flex-start">
      <ListIcon
        as={PiCaretDoubleRightBold}
        color="bourgeon.100"
        boxSize={4}
        marginTop={2}
      />
      <Box>
        <Text as="span" fontWeight="600">
          {strongText}
        </Text>
        <br />
        <Text as="span" letterSpacing={{ lg: '-1px', xl: 'normal' }}>{regularText}</Text>
      </Box>
    </ListItem>
  );
}

export default function HeroBannerHome() {
  const { isOpen, onToggle } = useDisclosure();
  const [isLargerThan414] = useMediaQuery('(min-width: 414px)');
  const randomIndex = Math.floor(Math.random() * heroImages.length);
  const randomImage = heroImages[randomIndex];
  return (
    <Box
      width="100%"
      backgroundColor="brand.100"
      color="white"
      position={{ md: 'relative' }}
    >
      <HeroBackgroundImage image={randomImage.md.src} color="brand.100" />

      <VStack align="stretch" justify="space-between" spacing={0}>
        <VStack
          justify={{ base: 'center' }}
          spacing={0}
          width={{ based: '100%', md: '50%' }}
          position="relative"
          zIndex={1}
          color="white"
          maxWidth={{ md: '50%' }}
          flexGrow={{ md: 1 }}
          flexShrink={{ md: 0 }}
          minHeight={{ md: '100%' }}
        >
          {/* Text content */}
          <VStack
            alignSelf={{ md: 'flex-end' }}
            minHeight={{ md: '100%' }}
            flexGrow={{ md: 1 }}
            pt="2rem"
            pb={{ md: '2rem' }}
          >
            {/* h1 */}
            <UIWidth isHalfForMd>
              <Heading
                as="h1"
                variant="hero"
                fontSize={[
                  '1.5rem',
                  '',
                  '1.5rem',
                  'clamp(1rem, 2.5vw, 2.75rem)',
                ]}
                letterSpacing="-.125px"
                color="inherit"
                mt={{ md: '1.5rem' }}
                mb={{ md: '1rem' }}
              >
                <Text as="span" fontWeight="normal">
                  <Text as="strong" fontWeight="600">
                    Avec IFprofs, simplifiez
                  </Text>{' '}
                  et{' '}
                  <Text as="strong" fontWeight="600">
                    enrichissez
                  </Text>{' '}
                  votre quotidien professionnel
                </Text>
              </Heading>
            </UIWidth>

            {/* Open/close button for mobile */}
            <Box textAlign="center" my="1rem" display={{ md: 'none' }}>
              <UIWidth>
                <Button
                  onClick={onToggle}
                  variant="link"
                  p={0}
                  minWidth={0}
                  _hover={{
                    color: 'brand.100',
                    backgroundColor: 'white',
                  }}
                >
                  <VisuallyHidden>
                    {isOpen ? 'Refermer le panneau' : 'En savoir plus'}
                  </VisuallyHidden>
                  <Flex
                    as="span"
                    width="2rem"
                    h="2rem"
                    border="solid 1px"
                    borderColor="white"
                    justify="center"
                    align="center"
                    borderRadius="full"
                  >
                    <Icon
                      as={PiPlusThin}
                      pl={0}
                      pr={0}
                      pt={0}
                      pb={0}
                      boxSize={4}
                    />
                  </Flex>
                </Button>
              </UIWidth>
            </Box>

            {/* List */}
            <UIWidth isHalfForMd>
              <Box
                transition="max-height 1s ease-in-out"
                maxHeight={
                  isOpen
                    ? { base: '180vw', sm: '50vh' }
                    : { base: '0', md: 'revert' }
                }
                overflow={{ base: 'hidden', md: 'revert' }}
              >
                <Box display="flex" alignItems="center" justifyContent="center">
                  <UIWidth>
                    <List spacing={4} fontSize={{ lg: '1.25rem' }} m={0}>
                      <HeroBannerListItem
                        strongText="gagnez du temps"
                        regularText="grâce à une sélection de ressources thématiques"
                      />
                      <HeroBannerListItem
                        strongText="développez vos compétences"
                        regularText="en suivant des formations et des webinaires"
                      />
                      <HeroBannerListItem
                        strongText="échangez des informations"
                        regularText="et des conseils avec vos communautés"
                      />
                    </List>
                  </UIWidth>
                </Box>
              </Box>
            </UIWidth>

            {/* Image for mobile only */}
            <Box
              maxHeight={isOpen ? '0vw' : '62.5vw'}
              transition="max-height 1s ease-in-out"
              overflow="hidden"
              width="100%"
              mb="2rem"
              display={{ md: 'none' }}
            >
              <Image
                src={randomImage.xs.src}
                alt=""
                width={{ base: '100%', sm: 'auto' }}
                height="auto"
                maxWidth={{ sm: '32rem' }}
                mx={{ sm: 'auto' }}
              />
            </Box>
          </VStack>

          {/* Mobile only CTA */}
          <VStack
            spacing={4}
            pb="2rem"
            align="stretch"
            display={{ base: 'flex', md: 'none' }}
          >
            <NextLink
              as={'/ressources'}
              passHref
              href={{
                pathname: '/ressources',
              }}
            >
              <Button variant="tuile50" as="a">
                <IconForButton
                  as={PiFoldersThin}
                  display={isLargerThan414 ? 'inline-flex' : 'none'}
                />
                Découvrir les ressources
              </Button>
            </NextLink>
            <NextLink
              as={'/formations'}
              passHref
              href={{
                pathname: '/formations',
              }}
            >
              <Button variant="menthe50" as="a">
                <IconForButton
                  as={PiMonitorThin}
                  display={isLargerThan414 ? 'inline-flex' : 'none'}
                />
                Explorer les formations
              </Button>
            </NextLink>
            <NextLink
              as={'/communautes'}
              passHref
              href={{
                pathname: '/communautes',
              }}
            >
              <Button variant="tournesol50" as="a">
                <IconForButton
                  as={PiGlobeSimpleThin}
                  display={isLargerThan414 ? 'inline-flex' : 'none'}
                />
                <Text whiteSpace="noWrap">L’espace Communauté</Text>
              </Button>
            </NextLink>
          </VStack>
        </VStack>

        {/* Landscape tablets only CTA */}
        <UIWidth>
          <HStack
            spacing="1.875rem"
            pb="2rem"
            align="stretch"
            display={{ base: 'none', md: 'flex' }}
            position="relative"
            width="fit-content"
            mx="auto"
          >
            <NextLink
              as={'/ressources'}
              passHref
              href={{
                pathname: '/ressources',
              }}
            >
              <Button variant="tuile50" as="a">
                <IconForButton as={PiFoldersThin} />
                Découvrir les ressources
              </Button>
            </NextLink>
            <NextLink
              as={'/formations'}
              passHref
              href={{
                pathname: '/formations',
              }}
            >
              <Button variant="menthe50" as="a">
                <IconForButton as={PiMonitorThin} />
                Explorer les formations
              </Button>
            </NextLink>
            <NextLink
              as={'/communautes'}
              passHref
              href={{
                pathname: '/communautes',
              }}
            >
              <Button variant="tournesol50" as="a">
                <IconForButton as={PiGlobeSimpleThin} />
                <Text as="span" display={{ base: 'none', lg: 'inline' }}>
                  Rejoindre&nbsp;
                </Text>
                <Text
                  as="span"
                  textTransform={{ base: 'uppercase', lg: 'none' }}
                >
                  l
                </Text>
                ’espace Communauté
              </Button>
            </NextLink>
          </HStack>
        </UIWidth>
      </VStack>
    </Box>
  );
}
